import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';

import Head from 'components/globals/head';

ReactModal.setAppElement('#___gatsby');

type LayoutNoneProps = {
  children: ReactNode
} 

const Layout = ({ children }:LayoutNoneProps) => {
  return (
    <div className="layout-wrapper">
      <Head />
      <ModalProvider>
        <div role="main">{children}</div>
      </ModalProvider>
    </div>
  );
};

export default Layout;
